export default {
    methods: {
        incrementDate(dateString, dayNumber) {
            const parts = dateString.split("-");
            const dt = new Date(
                parseInt(parts[0], 10),      // year
                parseInt(parts[1], 10) - 1,  // month (starts with 0)
                parseInt(parts[2], 10)       // date
            );
            dt.setDate(dt.getDate() + dayNumber);
            parts[0] = "" + dt.getFullYear();
            parts[1] = "" + (dt.getMonth() + 1);
            if (parts[1].length < 2) {
                parts[1] = "0" + parts[1];
            }
            parts[2] = "" + dt.getDate();
            if (parts[2].length < 2) {
                parts[2] = "0" + parts[2];
            }
            return parts.join("-");
        },

        // 空期間チェック
        validateEmptyTerm() {
            let isValidForm = true;
            const termList = [...this.form.countTermList]
            // 日付が古い順(昇順・asc)のSort
            termList.sort(function(a, b){
                return new Date(a.countTermStartDate) - new Date(b.countTermStartDate);
            });
            // 空期間あるかどうかチェック
            isValidForm = termList.every((obj, index) => {

                // 先頭に空白期あるかチェック
                if (index === 0) {
                    if (this.form.priorConsultationStartDate !== obj.countTermStartDate) {
                        return false;
                    }
                }
                // 後尾の要素ではない場合
                if(index !== termList.length - 1) {
                    let correctNextTermStartDate = this.incrementDate(obj.countTermEndDate, 1);
                    if (correctNextTermStartDate !== termList[index+1].countTermStartDate) {
                        return false;
                    }
                } else {
                    // 後尾に空白期あるかチェック
                    if (obj.countTermEndDate !== this.form.priorConsultationEndDate) {
                        return false;
                    }
                }
                return true;
            })

            if (isValidForm === false) {
                this.validate.countTermStartDateList.forEach(v => {
                    v.errMsg = "集計期間は事前協議書期間を網羅するように設定してください。"
                })
            }
            return isValidForm;
        },
    }
}
